<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z" id="file_upload_svg__a"/></defs><use fill="#DC333E" fill-rule="nonzero" xlink:href="#file_upload_svg__a"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconFileUpload'
  };
</script>
