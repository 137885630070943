<template>
  <div class="tax" :class="taxClass">
    <Banner isBig />

    <div class="tax-content page-layout">
      <template v-if="isAdmin">
        <PageHeader :title="$t('income-tax.title')" @back="goBack" />

        <p>
          {{ $t("income-tax.description") }}
        </p>
      </template>
      <template v-else>
        <LoadingCard v-if="isLoading" />
        <template v-else>
          <PageHeader
            :has-back="true"
            :title="$t('income-tax.title')"
            @back="goBack"
          />
          <a
            href="https://cfspart.impots.gouv.fr/enp/ensu/dpr.do"
            target="_blank"
            class="tax-content__download"
          >
            <MeepIconDownload class="app-icon" />
            <p class="tax-content__download-text">
              {{ $t("income-tax.button-download-text") }}
            </p>
          </a>
          <div class="tax-content__note">
            <p class="tax-content__note-title tax-content--text-bold">
              {{ $t("income-tax.note.title") }}
            </p>
            <ul class="tax-content--text-normal">
              <li
                v-for="(value, key) of $t('income-tax.note.details')"
                :key="key"
              >
                • {{ value }}
              </li>
            </ul>
          </div>
          <div class="tax-content__upload">
            <p class="tax-content--text-bold">
              {{ $t("income-tax.upload.title") }}
            </p>
            <md-card-content>
              <div class="meep-form-column meep-form-single-column">
                <div class="meep-input">
                  <md-field>
                    <md-file
                      accept=".json"
                      @md-change="onFileAdded($event)"
                      multiple
                      :placeholder="$t('income-tax.upload.input-placeholder')"
                      ref="file"
                      readonly
                    />
                  </md-field>
                </div>
                <span v-show="messError" class="md-error">
                  {{ messError }}
                </span>

                <div class="md-layout">
                  <md-button
                    class="md-raised md-primary"
                    @click="$refs.file.openPicker()"
                  >
                    <MeepIconFileUpload class="app-icon" />
                    <span> {{ $t("income-tax.upload.button-text") }}</span>
                  </md-button>
                </div>
                <div class="md-layout">
                  <md-button
                    class="md-raised md-primary app-jepa__background tax-content__button-submit"
                    @click="save"
                  >
                    {{ $t("income-tax.button") }}
                  </md-button>
                </div>
              </div>
            </md-card-content>
          </div>
          <div class="tax-content__store">
            <p class="tax-content__store-description tax-content--text-normal">
              {{ $t("income-tax.description") }}
            </p>
            <div class="tax-content__store-apps">
              <a :href="iOsUrl">
                <img
                  alt="apple_store"
                  src="../../assets/images/apple_store_jepa.png"
                />
              </a>
              <a :href="androidUrl">
                <img
                  alt="apple_store"
                  src="../../assets/images/google_play_jepa.png"
                />
              </a>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import PageHeader from "@/components/PageHeader";
import HasBackMixin from "@/mixins/HasBackMixin";
import files from "@/model/files";
import { mapGetters } from "vuex";
import LoadingCard from "@/components/LoadingCard.vue";
import MeepIconDownload from "@/components/icons/MeepIconDownload.vue";
import MeepIconFileUpload from "@/components/icons/MeepIconFileUpload.vue";

export default {
  name: "Tax",

  components: {
    PageHeader,
    Banner,
    LoadingCard,
    MeepIconDownload,
    MeepIconFileUpload,
  },

  mixins: [HasBackMixin],

  data() {
    return {
      filesUpload: {
        declaration: [],
      },
      isLoading: false,
      messError: "",
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isJEPA", "iOsUrl", "androidUrl"]),

    taxClass() {
      return {
        "tax--jepa": this.isJEPA,
      };
    },
    isExistNotJsonFile() {
      return this.filesUpload["declaration"].some(
        (file) => file.type !== "application/json"
      );
    },
    isEmptyData() {
      return !this.filesUpload.declaration.length;
    },
  },

  methods: {
    onFileAdded(files) {
      if (!files) return;
      this.filesUpload.declaration = Array.from(files);
      this.isExistNotJsonFile
        ? (this.messError = this.$t("income-tax.not-json-file"))
        : (this.messError = "");
    },
    async save() {
      if (!this.isExistNotJsonFile && !this.isEmptyData) {
        this.isLoading = true;
        try {
          await files.uploadTax({
            type: "introduction-income",
            files: this.filesUpload.declaration,
          });

          this.$toasted.global.AppSucces({
            message: this.$t("income-tax.upload.success"),
          });
        } catch (error) {
          console.log("error", error);
          this.$toasted.global.AppError({
            message: this.$t("income-tax.upload.error"),
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        if (this.isEmptyData) this.messError = this.$t("income-tax.require");
        this.$toasted.global.AppInfo({
          message: this.isEmptyData
            ? this.$t("income-tax.require")
            : this.$t("income-tax.not-json-file"),
        });
      }
    },
  },

  mounted() {
    if (!this.isJEPA) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
#app {
  .tax {
    &.tax--jepa {
      .banner--jepa {
        margin-bottom: 0;
      }
      .tax-content {
        margin-left: 36px;

        padding: 47px 0 88px 0;

        .page-header {
          &--jepa {
            padding: 0;
            margin-bottom: 0;
          }
          &__title {
            margin-bottom: 26px;
            .page-title {
              font-size: 25px;
            }
          }
        }
        &__download {
          display: flex;
          flex-direction: column;
          width: 377px;
          background-color: var(--bg-primary);
          padding: 11px 27px 28px;
          border-radius: 21px;
          cursor: pointer;
          &-text {
            font-family: var(--font-bold);
            color: white;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            margin-left: 0;
          }
          .app-icon {
            width: 72px;
            height: auto;
            margin: 0 auto;
            path {
              fill: white;
            }
          }
        }
        &__note {
          padding: 36px 0;
          &-title {
            padding-bottom: 26px;
          }
        }
        &__upload {
          .md-card-content {
            padding: 27px 0 75px 0;
            color: white;
            .md-field {
              &.md-has-file {
                margin-bottom: 0;
                .md-file-icon {
                  display: none;
                }
                .md-input {
                  margin-left: 0;
                  background-color: white;
                  max-width: 40%;
                  padding: 19px 0 23px 17px;
                }
              }
            }
          }
          .md-error {
            margin-top: 0;
          }
          .md-button {
            margin: 20px 0 0;
            font-size: 17px;
            font-weight: 300;
            padding: 16px 18px;
            width: 300px;
            height: 60px;
            font-family: var(--font-x-light);
            text-transform: none;

            &-content {
              display: flex;
              align-items: center;
              gap: 17px;
            }
            .app-icon {
              path {
                fill: currentColor;
              }
            }
          }
        }
        &__button-submit {
          &.md-button {
            width: 375px;
            height: 65px;
            padding: 19px 39px;
            margin-top: 53px;
            font-size: 23px;
            font-weight: 900;
            font-family: var(--font-black);
          }
        }
        &__store {
          &-description {
            max-width: 50%;
          }
          &-apps {
            margin-top: 29px;
            display: flex;
            gap: 19px;
            img {
              cursor: pointer;
            }
          }
        }

        &--text-bold {
          font-family: var(--font-bold);
          font-size: 25px;
          color: var(--text-bold);
          font-weight: bold;
          line-height: 25.5px;
        }
        &--text-normal {
          font-family: var(--font-book);
          font-size: 18px;
          color: var(--text-alternate);
          line-height: 25.5px;
        }
      }
    }
  }
}
</style>
