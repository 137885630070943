<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><defs/><g id="download_svg__Groupe_1040" transform="translate(-1825 -210)"><g id="download_svg__Groupe_645" transform="translate(1569.698 -710.16)"><path id="download_svg__Tracé_513" d="M291.951 1124.8h-22.3a3.637 3.637 0 01-3.782-3.464v-2.351a2.2 2.2 0 014.378 0v.725a1.032 1.032 0 001.031 1.031h19.045a1.032 1.032 0 001.031-1.031v-.725a2.2 2.2 0 014.378 0v2.351a3.637 3.637 0 01-3.781 3.464zm-23.891-6.653a.929.929 0 00-1 .838v2.351a2.45 2.45 0 002.59 2.272h22.3a2.45 2.45 0 002.59-2.272v-2.351a1.012 1.012 0 00-1.994 0v.725a2.225 2.225 0 01-2.222 2.222h-19.045a2.225 2.225 0 01-2.222-2.222v-.725a.929.929 0 00-.997-.837z" transform="translate(0 -165.407)"/><path id="download_svg__Tracé_514" d="M320.787 953.245a2.1 2.1 0 01-1.49-.616l-7.941-7.942a2.195 2.195 0 013.1-3.1l3.842 3.842v-11.49a2.485 2.485 0 114.971 0v11.486l3.842-3.842a2.195 2.195 0 013.1 3.1l-7.942 7.942a2.1 2.1 0 01-1.482.62zm-7.88-11.112a1 1 0 00-.709 1.712l7.942 7.942a.917.917 0 001.294 0l7.942-7.942a1 1 0 10-1.418-1.419l-4.859 4.859a.6.6 0 01-1.017-.421v-12.925a1.294 1.294 0 00-2.587 0v12.925a.6.6 0 01-1.017.421l-4.859-4.859a1 1 0 00-.712-.293z" transform="translate(-39.985)"/></g></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconDownload'
  };
</script>
